import React from 'react'
import JobEditComponent from '../../../../components/employerDashboard/jobs/edit'
import Layout from '../../../../components/employerDashboard/layout'
const EditJobPage = ({ location }) => {
  return (
    <Layout title="Edit Job | MedReps.com" location={location}>
      <JobEditComponent />
    </Layout>
  )
}
export default EditJobPage
